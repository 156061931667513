<template>
  <div class="right">
    <div class="blackboard">
      <div class="tit">现在为作品绘制图片</div>
      <div class="messageBox">
        <div class="message">
          <div class="msgTit">
            {{ article.title }}
          </div>
          <div class="msgCon">{{ article.description }}</div>
        </div>
        <el-button type="text" @click="$router.go(-1)">
          ← 返回文本创作</el-button
        >
      </div>
      <div class="jieShao">
        每篇作品最多可绘制 <span>5</span> 次配图，每次输出
        <span>2</span> 张图片；最终可从所有配图中选取
        <span>3</span> 张进行发布。
      </div>
    </div>
    <div class="inputBox">
      <span style="line-height: 40px">图片内容：</span>
      <div class="inputCon">
        <div class="top">
          <el-input
            v-model="description"
            placeholder="请输入内容提示词，不超过150字"
            @keyup.enter.native="huiZhi"
          >
          </el-input>
          <el-button
            type="primary"
            style="
              width: 100px;
              color: #000;
              font-weight: 600;
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
            "
            @click="huiZhi"
            >绘制</el-button
          >
        </div>
        <div class="bottom" v-if="!floor.length">
          <div class="text">
            <i class="el-icon-picture"></i>
            <span>图片绘制区</span>
          </div>
        </div>
        <div class="floor" v-for="(item, index) in floor" :key="index + 1">
          <div class="floorItem">
            <div class="indexTit">绘图{{ index + 1 }}</div>
            <div class="floorItemTit">{{ item.drawDesc }}</div>
            <div class="Img">
              <div class="haveImg" v-if="item.jobStatus == 2">
                <el-image
                  style="width: 40%; margin-right: 1%; margin-left: 1%"
                  :src="item.imageList && item.imageList[0]"
                ></el-image>
                <div class="setMargin" v-if="item.checked1" style="left: 2%">
                  <el-radio v-model="radio" :label="item.imageList[0]"
                    >设为主图</el-radio
                  >
                </div>
                <div class="setMargin" v-if="item.checked2" style="left: 43%">
                  <el-radio v-model="radio" :label="item.imageList[1]"
                    >设为主图</el-radio
                  >
                </div>
                <el-checkbox
                  style="
                    position: absolute;
                    bottom: 6px;
                    left: 38%;
                    z-index: 999;
                  "
                  v-model="item.checked1"
                ></el-checkbox>
                <el-checkbox
                  style="
                    position: absolute;
                    bottom: 6px;
                    left: 79%;
                    z-index: 999;
                  "
                  v-model="item.checked2"
                ></el-checkbox>
                <el-image
                  style="width: 40%"
                  :src="item.imageList && item.imageList[1]"
                ></el-image>
              </div>
              <div class="nohaveImg" v-if="item.jobStatus == 1">
                <div class="text">
                  <i class="el-icon-loading"></i>
                  <span>图片绘制中，请耐心等待...</span>
                </div>
                <el-progress
                  :text-inside="true"
                  :stroke-width="20"
                  :color="colors"
                  :percentage="percentage"
                ></el-progress>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-button
      type="primary"
      style="width: 100%; margin-top: 40px; color: #000; font-weight: 600"
      @click="release"
    >
      <div class="but">
        <img src="@/assets/images/xiaohuojian.png" alt="" />
        <span>我要发布</span>
      </div>
    </el-button>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  imageGenerate,
  searchImageResult,
  save,
  searchHistoryImg,
  nftArticleIdNum,
} from "@/api/nft";

export default {
  name: "Create",
  data() {
    return {
      timer: null,
      floor: [
        {
          status: 2,
          drawDesc: "",
          imageList: [1, 2],
        },
      ],
      nowId: "",
      percentage: 0,
      radio: "",
      colors: [
        { color: "#f56c6c", percentage: 20 },
        { color: "#e6a23c", percentage: 40 },
        { color: "#5cb87a", percentage: 60 },
        { color: "#1989fa", percentage: 80 },
        { color: "#6f7ad3", percentage: 100 },
      ],
      description: "",
    };
  },
  mounted() {
    this.getHistory();
  },
  methods: {
    async getHistory() {
      let { data, code } = await searchHistoryImg({
        articleId: this.agoId, //	文章id,开篇传0
      });
      if (code == 1000) {
        this.floor = data.map((i) => {
          if (i.status == 2) {
            return {
              ...i,
              jobStatus: 2,
            };
          }
        });
        let last = data[data.length - 1];
        if (last.status == 1) {
          this.oldHuiZhi(last.drawDesc, last.jobId);
        }
      }
    },
    // 发布
    release() {
      let imagetListJson = [];
      this.floor.forEach((i) => {
        if (i.checked1) {
          imagetListJson.push({ url: i.imageList[0], hasMainImage: 0 });
        }
        if (i.checked2) {
          imagetListJson.push({ url: i.imageList[1], hasMainImage: 0 });
        }
      });

      if (!imagetListJson.length) {
        this.$alert("请至少勾选一张图片作品用以发布", "提示", {});
        return;
      }
      if (imagetListJson.length>=4) {
        this.$alert("每篇作品最多仅可发布3张图片", "提示", {});
        return;
      }
      if (!this.radio) {
        this.$alert("请选择作品主图", "提示", {});
        return;
      }
      let param = {
        description: this.article.description,
        brief: this.article.title,
        title: this.article.title,
        articleId: this.agoId,
        accountId: this.accountId,
        imagetListJson: JSON.stringify(imagetListJson),
        mianImg: this.radio,
      };
      save(param).then(({ data, code }) => {
        if (code == 1000) {
          this.$router.push("/success");
          // 存文章id
          this.$store.commit("SETAGOID", data);
        } else {
          this.$message(data.failReason);
        }
      });
    },
    huiZhi() {
      // 计数器归零
      this.percentage = 0;
      let param = {
        accountId: this.accountId,
        description: this.description,
        uuid: this.getRandom(10),
        articleId: this.agoId,
      };
      // 计数器占位
      this.floor.push({
        drawDesc: this.description,
        jobStatus: 1,
      });
      imageGenerate(param).then(({ data, code }) => {
        if (code == 1000) {
          this.nowId = data[0].jobId;
          this.timerInfo();
        } else {
          this.$alert("画图机器人暂时过于繁忙，请稍后再试", "抱歉！出错了", {});
          this.floor.pop();
        }
      });
    },
    // 当刷新页面时
    oldHuiZhi(drawDesc, jobId) {
      // 计数器归零
      this.percentage = 0;
      // 计数器占位
      this.floor.push({
        drawDesc: drawDesc,
        jobStatus: 1,
      });
      this.nowId = jobId;
      this.timerInfo();
    },
    getRandom(num) {
      let random = Math.floor(
        (Math.random() + Math.floor(Math.random() * 9 + 1)) *
          Math.pow(10, num - 1)
      );
      return random;
    },
    timerInfo() {
      this.timer = setInterval(() => {
        if (this.percentage < 100) {
          this.percentage = this.FloatAdd(this.percentage);
        } else {
          clearInterval(this.timer);
          this.percentage = 0;
          this.$alert("画图机器人暂时过于繁忙，请稍后再试", "抱歉！出错了", {});
        }
      }, 120);
    },
    // 最后一个对象图片赋值函数
    arrFun() {
      let param = {
        jobId: this.nowId,
      };
      searchImageResult(param).then(({ data, code }) => {
        if (code == 1000) {
          if (data.jobStatus == 2) {
            // 去掉计数器，替换成数据
            this.floor.pop();
            data.drawDesc = this.description;
            this.floor.push(data);
            // 清空计数器
            clearInterval(this.timer);
            this.percentage = 0;
            // 清空提问
            this.description = "";
          } else if (data.jobStatus == 3) {
            this.floor.pop();
            clearInterval(this.timer);
            this.percentage = 0;
            this.$alert(
              "画图机器人暂时过于繁忙，请稍后再试",
              "抱歉！出错了",
              {}
            );
          }
        }
      });
    },

    //浮点数加法运算
    FloatAdd(arg1) {
      var r1, r2, m;
      try {
        r1 = arg1.toString().split(".")[1].length;
      } catch (e) {
        r1 = 0;
      }
      try {
        r2 = (0.1).toString().split(".")[1].length;
      } catch (e) {
        r2 = 0;
      }
      m = Math.pow(10, Math.max(r1, r2));
      return (arg1 * m + 0.1 * m) / m;
    },
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
      accountId: (state) => state.user.accountId,
      nft: (state) => state.page.nft,
      article: (state) => state.page.article,
      agoId: (state) => state.page.agoId,
    }),
  },
  watch: {
    percentage(newVal) {
      if (
        newVal == 50 ||
        newVal == 60 ||
        newVal == 70 ||
        newVal == 80 ||
        newVal == 90 ||
        newVal == 95 ||
        newVal == 99
      ) {
        this.arrFun();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.right {
  margin-top: 5vh;
  padding-bottom: 5vh;
  .but {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .blackboard {
    width: 100%;

    .tit {
      margin: 8px 0px;
      background: linear-gradient(
        0deg,
        rgba(1, 227, 155, 0.3) -3%,
        rgba(247, 239, 19, 0.3) 96%
      );
      line-height: 32px;
      text-align: center;
      border-radius: 16px;
    }
    .messageBox {
      display: flex;
      align-items: center;
      flex-flow: column;
      box-sizing: border-box;
      width: 100%;
      height: 230px;
      padding: 10px 20px;
      background: #f2f2f227;
      border-radius: 16px;
      .message {
        width: 100%;
        height: 176px;
        overflow-y: auto;
        line-height: 24px;
        .msgTit {
          font-size: 16px;
          font-weight: 600;
          line-height: 50px;
        }
      }
      .message::-webkit-scrollbar {
        width: 6px;
      }
      .message::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: rgba(249, 249, 249, 0.2);
      }
      .message::-webkit-scrollbar-track {
        border-radius: 0;
        background: rgba(249, 249, 249, 0.1);
      }
    }
    .jieShao {
      color: #01e39b;
      line-height: 60px;
      span {
        color: #f4ea29;
      }
    }
  }
  .inputBox {
    display: flex;
    .inputCon {
      flex: 1;
      .top {
        flex: 1;
        display: flex;
        align-items: center;
        /deep/ .el-input {
          height: 40px;
          border: none;
          box-sizing: border-box;

          .el-input__inner {
            height: 40px;
            display: block;
            background: rgba(249, 249, 249, 0.02);
            border: 1px solid #8e8e8e;
            border-right: none;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
      }
      .bottom {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 180px;
        margin-top: 20px;
        background: #f2f2f227;
        border-radius: 4px;
        .text {
          display: flex;
          align-items: center;
          color: #828282;
          i {
            font-size: 24px;
            margin-right: 12px;
          }
        }
      }
      .floor {
        margin-top: 40px;
        .floorItem {
          padding-top: 60px;
          width: 100%;
          display: flex;
          align-items: center;
          box-sizing: border-box;
          position: relative;
          .floorItemTit {
            position: absolute;
            top: 0px;
            left: 10px;
            font-size: 16px;
            color: #828282;
          }
          .indexTit {
            position: absolute;
            top: -10px;
            left: -62px;
            padding: 10px 12px;
            border-radius: 4px;
            font-size: 14px;
            border: 1px solid rgba(170, 170, 170, 0.4157);
          }
          .Img {
            width: 100%;
            // height: 180px;
          }
          .haveImg {
            width: 100%;
            height: 100%;
            position: relative;
            display: flex;
            .setMargin {
              width: 136px;
              height: 36px;
              font-size: 16px;
              border-radius: 20px;
              background: rgba(0, 0, 0, 0.6);
              position: absolute;
              z-index: 99;
              display: flex;
              align-items: center;
              justify-content: center;
              bottom: 6px;
            }
            /* 背景透明 */
            /deep/.el-checkbox__inner {
              background: transparent;
              border: 1px solid #01e39b;
            }
            /* 选中样式 */
            /deep/.el-checkbox__input.is-checked .el-checkbox__inner,
            .el-checkbox__input.is-indeterminate .el-checkbox__inner {
              background: #01e39b;
              border: 1px solid #01e39b;
            }
            /* 大小 */
            /deep/.el-checkbox__inner {
              width: 20px;
              height: 20px;
            }
            /* 大小 */
            /deep/.el-radio__inner {
              width: 20px;
              height: 20px;
            }
            /* 对勾样式 */
            /deep/.el-checkbox__inner::after {
              border: 3px solid #000;
              border-left: 0;
              border-top: 0;
              left: 6px;
              top: 3px;
            }
            /deep/.el-checkbox__input.is-checked .el-checkbox__inner::after {
              transform: rotate(50deg) scaleY(1.3);
            }
          }
          .nohaveImg {
            box-sizing: border-box;
            width: 70%;
            margin-left: 5%;
            height: 100%;
            padding: 70px 10px 10px 20px;
            .text {
              text-align: center;
              color: #828282;
              margin-bottom: 60px;
              i {
                font-size: 20px;
                margin-right: 10px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
